export const DISPLAY_CONTEXT_B2B_ONLY = "B2B Only";
export const DISPLAY_CONTEXT_B2C_ONLY = "B2C Only";
export const DISPLAY_CONTEXT_ALWAYS_VISIBLE = "Always Visible";

export const PAYMENT_STATUS_INVOICED = "Invoiced";
export const PAYMENT_STATUS_OVERDUE = "Overdue";
export const PAYMENT_STATUS_AWAITING_PAYMENT = "Awaiting payment";
export const PAYMENT_STATUS_PATIENT_TO_PAY = "Patient to pay";
export const PAYMENT_STATUS_PAID = "Paid";
export const PAYMENT_STATUS_PATIENT_PAID = "Patient Paid";
export const PAYMENT_STATUS_PARTIAL_REFUND = "Partial refund";
export const PAYMENT_STATUS_REFUNDED = "Refunded";
export const PAYMENT_STATUS_UNPAID = "Unpaid";

export const PROGRESS_STATUS_ORDERED = "Ordered";
export const PROGRESS_STATUS_AWAITING_DISPATCH = "Awaiting Dispatch";
export const PROGRESS_STATUS_DISPATCHED = "Dispatched";
export const PROGRESS_STATUS_PARTIAL_DISPATCH = "Partial Dispatch";
export const PROGRESS_STATUS_IN_TRANSIT = "In Transit";
export const PROGRESS_STATUS_AT_LAB = "At Lab";
export const PROGRESS_STATUS_FAILED = "Failed";
export const PROGRESS_STATUS_PARTIAL_FAILURE = "Partial Failure";
export const PROGRESS_STATUS_CANCELLED = "Cancelled";
export const PROGRESS_STATUS_PARTIALLY_CANCELLED = "Partially Cancelled";
export const PROGRESS_STATUS_REVIEW_INTERIM_RESULTS = "Review Interim Results";
export const PROGRESS_STATUS_REVIEW_RESULTS = "Review Results";
export const PROGRESS_STATUS_INTERIM_RESULTS_RELEASED = "Interim Results Released";
export const PROGRESS_STATUS_RESULTS_RELEASED = "Results Released";
export const PROGRESS_STATUS_PARTIAL_RESULTS_RELEASED = "Partial Results Released";

export const STATUS_VARIANTS = {
  // Payment Status
  [PAYMENT_STATUS_INVOICED]: "filled",
  [PAYMENT_STATUS_OVERDUE]: "failure",
  [PAYMENT_STATUS_AWAITING_PAYMENT]: "failure",
  [PAYMENT_STATUS_PATIENT_TO_PAY]: "failure",
  [PAYMENT_STATUS_PAID]: "success",
  [PAYMENT_STATUS_UNPAID]: "failure",
  [PAYMENT_STATUS_PATIENT_PAID]: "success",
  [PAYMENT_STATUS_PARTIAL_REFUND]: "filled",
  [PAYMENT_STATUS_REFUNDED]: "filled",
  // Progress Status
  [PROGRESS_STATUS_ORDERED]: "unfilled",
  [PROGRESS_STATUS_AWAITING_DISPATCH]: "unfilled",
  [PROGRESS_STATUS_DISPATCHED]: "unfilled",
  [PROGRESS_STATUS_PARTIAL_DISPATCH]: "unfilled",
  [PROGRESS_STATUS_IN_TRANSIT]: "unfilled",
  [PROGRESS_STATUS_AT_LAB]: "unfilled",
  [PROGRESS_STATUS_FAILED]: "failure",
  [PROGRESS_STATUS_PARTIAL_FAILURE]: "failure",
  [PROGRESS_STATUS_CANCELLED]: "failure",
  [PROGRESS_STATUS_PARTIALLY_CANCELLED]: "failure",
  [PROGRESS_STATUS_REVIEW_INTERIM_RESULTS]: "notify",
  [PROGRESS_STATUS_REVIEW_RESULTS]: "notify",
  [PROGRESS_STATUS_INTERIM_RESULTS_RELEASED]: "success",
  [PROGRESS_STATUS_RESULTS_RELEASED]: "success",
  [PROGRESS_STATUS_PARTIAL_RESULTS_RELEASED]: "filled"
};

const LOWER_CASE_STATUS_VARIANTS = Object.fromEntries(
  Object.entries(STATUS_VARIANTS).map(([status, variant]) => [status.toLowerCase(), variant])
);

export function getStatusVariant(status) {
  return LOWER_CASE_STATUS_VARIANTS[status.toLowerCase()] || "unfilled";
}
