import { useEffect, useRef } from "react";

import Player from "@vimeo/player";
import Box from "components/Box";

export default function VimeoComponent({ onStart, onEnd, play = false, height, url, width, pt }) {
  const onStartRef = useRef(onStart);
  const onEndRef = useRef(onEnd);
  const containerRef = useRef(null);

  useEffect(() => {
    const options = {
      height,
      url,
      width,
      loop: false,
      responsive: true,
      autoplay: play
    };

    const player = new Player(url, options);

    player.on("play", () => {
      onStartRef.current?.(url);
    });

    player.on("ended", () => {
      onEndRef.current?.(url);
    });

    player.on("error", e => {
      console.log("error with vimeo component", e);
    });

    if (containerRef.current && play) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }

    return () => {
      player.destroy();
    };
  }, [url, width, height, play]);

  return <Box id={url} mx="auto" maxWidth={1274} width="100%" mt={pt} ref={containerRef} />;
}
