import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { useWatch } from "react-hook-form";

import { gql, useApolloClient } from "@apollo/client";
import { useAuthContext } from "components/AuthProvider";
import Box from "components/Box";
import CustomerPage from "components/CustomerPage";
import DataLoader from "components/DataLoader";
import Divider from "components/Divider";
import Grid from "components/Grid";
import { ExternalTextLink } from "components/Links";
import Page from "components/Page";
import OrderStatusBadges from "components/organisations/orders/ui/OrderStatusBadges";
import OrderTestItemStatusBadges from "components/organisations/orders/ui/OrderTestItemStatusBadges";
import { getAPIBase } from "core/config";
import { BASKET_PAYEE_PATIENT, PAYMENT_INITIATED } from "core/constants";
import { PLATFORM_USER_PROFILE_QUERY } from "graphql/accounts";
import { ORDER_QUERY } from "graphql/shop";
import Badge from "tpo/Badge";
import { PanelBoxV2 } from "tpo/Boxes";
import ChevronComponent from "tpo/Chevron";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import Jumbotron from "tpo/Jumbotron";
import NothingToShow from "tpo/NothingToShow";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import { UserBasketSummary } from "tpo/shop/Basket";
import {
  AuthenticatedUserCheckoutFormProvider,
  useCheckoutFormContext,
  GuestCheckoutFormProvider,
  AuthenticatedCheckoutForm,
  GuestCheckoutForm,
  AuthenticatedChargelessCheckoutForm,
  GuestChargelessCheckoutForm
} from "tpo/shop/Checkout";
import CheckoutTemplate, { CheckoutContext } from "tpo/shop/CheckoutTemplate";
import OrderSummary from "tpo/shop/OrderSummary";
import StripePaymentFormContent from "tpo/shop/StripePaymentFormContent";
import {
  PAYMENT_STATUS_AWAITING_PAYMENT,
  PAYMENT_STATUS_PAID,
  PAYMENT_STATUS_PATIENT_PAID,
  PAYMENT_STATUS_PATIENT_TO_PAY
} from "tpo/shop/constants";
import ButtonV2 from "v2/Buttons";

import OrderDetailTemplate from "./OrderDetailTemplate";

export function OrderDetails({
  id,
  orderNo,
  orderDate,
  estimatedResultsAvailableDate,
  shippingFullName,
  user,
  derivedPaymentStatus,
  derivedProgressStatus,
  sourceBasket,
  status,
  showPaymentStatus
}) {
  return (
    <Box
      display="flex"
      flexDirection={["column", "column", "row"]}
      justifyContent={["flex-start", "flex-start", "space-between"]}
      py={4}
      gap={20}
    >
      <Stack gap={10}>
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
            Order No:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} data-testid="orderDetails:orderNo">
            {orderNo}
          </Box>
        </Group>
        {sourceBasket &&
        sourceBasket.payee === BASKET_PAYEE_PATIENT &&
        status === PAYMENT_INITIATED ? (
          <Group gap={10}>
            <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
              Payment link:
            </Box>
            <ExternalTextLink
              onClick={e => {
                e.clickWithinOrderDetailsComponent = true;
              }}
              href={`${getAPIBase()}/payments/checkout/${id}`}
              data-testid="paymentLink"
            >
              Payment Link
            </ExternalTextLink>
          </Group>
        ) : null}
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
            Order Date:
          </Box>
          {!!orderDate && (
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              data-testid="orderDetails:orderDate"
            >
              {new Date(orderDate).toLocaleDateString("en-GB")}
            </Box>
          )}
        </Group>
        {!!estimatedResultsAvailableDate && (
          <Group gap={10}>
            <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
              Results expected:
            </Box>
            {!!estimatedResultsAvailableDate && (
              <Box
                fontFamily="gilroyMedium"
                fontSize={[14, 14, 16]}
                data-testid="orderDetails:orderDate"
              >
                {new Date(estimatedResultsAvailableDate).toLocaleDateString("en-GB")}
              </Box>
            )}
          </Group>
        )}
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
            Name:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} data-testid="orderDetails:name">
            {user?.fullName || shippingFullName}
          </Box>
        </Group>
      </Stack>
      <Box>
        <OrderStatusBadges
          order={{
            id,
            derivedPaymentStatus,
            derivedProgressStatus,
            sourceBasket
          }}
          showPaymentStatus={showPaymentStatus}
          derivedPaymentStatusOverrides={{
            [PAYMENT_STATUS_PATIENT_TO_PAY]: PAYMENT_STATUS_AWAITING_PAYMENT,
            [PAYMENT_STATUS_PATIENT_PAID]: PAYMENT_STATUS_PAID
          }}
        />
      </Box>
    </Box>
  );
}

const GET_POSTAGE_COSTS_FOR_ORDER = gql`
  query GetPostageCostsForOrder($orderId: ID!, $shippingCountryName: String) {
    postageCostsForOrder(orderId: $orderId, shippingCountryName: $shippingCountryName)
  }
`;

const PostageCostsContext = createContext();

function usePostageCostsContext() {
  return useContext(PostageCostsContext);
}

function PostageCostsProvider({ children, order }) {
  const {
    methods: { control }
  } = useCheckoutFormContext();
  const [shippingCountry, id] = useWatch({
    control,
    name: ["shippingCountry", "id"]
  });

  const [postageCosts, setPostageCosts] = useState();

  const client = useApolloClient();

  useEffect(() => {
    // id is constant
    // shippingCountry varies based on user input in the checkout form
    // order will be a new ref if the basket changes
    // we need to requery for postage costs if the order ref has changed
    // because postage costs are calculated based on the order contents

    let canUpdate = true;

    client
      .query({
        query: GET_POSTAGE_COSTS_FOR_ORDER,
        fetchPolicy: "network-only",
        variables: {
          shippingCountryName: shippingCountry,
          orderId: id
        }
      })
      .then(res => {
        if (canUpdate) {
          setPostageCosts(res.data.postageCostsForOrder);
        }
      })
      .catch(error => {
        console.log("Error getting postage costs", error);
      });

    return () => {
      // order could be updated and now we are using chargeless, or chargeable checkout which will unmount this component
      canUpdate = false;
    };
  }, [order, shippingCountry, id, client]);

  let element = null;

  if (postageCosts !== undefined) {
    element = (
      <Currency prefix="SHIPPING" data-testid="shippingTotal" value={postageCosts} fontSize={28} />
    );
  }

  const api = useMemo(
    () => ({
      postageCosts,
      element
    }),
    [postageCosts, element]
  );

  return <PostageCostsContext.Provider value={api}>{children}</PostageCostsContext.Provider>;
}

function TotalWithPostage({ order }) {
  const { postageCosts } = usePostageCostsContext();

  const postageCostsFloat = parseFloat(postageCosts || 0);

  return (
    <Currency
      prefix="TOTAL"
      data-testid="totalWithPostage"
      value={(order.discountedTotal + postageCostsFloat).toFixed(2)}
      fontSize={44}
    />
  );
}

function formatBookingDatetime(datetime) {
  if (!datetime) {
    return "No booking details";
  }
  // Accept datetime in format "2023-06-09T09:15:00+0000"
  // and return in format "DD/MM/YYYY at HH:MM"
  const date = datetime.split("T")[0];
  const time = datetime.split("T")[1].split(":").slice(0, 2).join(":");
  return `${date.split("-").reverse().join("/")} at ${time}`;
}

function ListItemCard({
  name,
  providerTestId,
  price,
  orderTestItem,
  styles,
  currencySymbol = "£"
}) {
  return (
    <Grid gridTemplateColumns="auto 1fr" data-component-name="ListItemCard" {...styles}>
      <Box>
        <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]} data-testid="name">
          {name}
        </Box>
        {providerTestId && (
          <Box fontFamily="gilroyMedium" fontSize={14} data-testid="kitRegistrationId">
            {`Kit registration ID: ${providerTestId}`}
          </Box>
        )}
        {!!orderTestItem.estimatedResultsAvailableDate && (
          <Box fontFamily="gilroyMedium" fontSize={14} data-testid="estimatedResultsAvailableDate">
            <span style={{ fontWeight: "bold" }}>Results expected:</span>{" "}
            {new Date(orderTestItem.estimatedResultsAvailableDate).toLocaleDateString("en-GB")}
          </Box>
        )}
      </Box>
      <Stack
        pb={2}
        alignItems="flex-end"
        gridRowStart={["2", "2", "unset"]}
        gridRowEnd={["3", "3", "unset"]}
        gridColumnStart={["2", "2", "unset"]}
        gridColumEnd={["3", "3", "unset"]}
        justifySelf="end"
      >
        {price !== undefined && (
          <Currency symbol={currencySymbol} fontSize={28} value={price} data-testid="price" />
        )}
        <OrderTestItemStatusBadges orderTestItem={orderTestItem} alignItems="flex-end" />
      </Stack>
    </Grid>
  );
}

function WithClinicListItemCard({
  name,
  address,
  postcode,
  clinicFee,
  clinicName,
  price,
  providerTestId,
  orderId,
  clinicBooking,
  orderTestItem,
  currencySymbol = "£"
}) {
  return (
    <>
      <ListItemCard
        name={name}
        providerTestId={providerTestId}
        price={price}
        orderTestItem={orderTestItem}
        currencySymbol={currencySymbol}
      />
      <Box>
        <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]} data-testid="clinicName">
          {clinicName}
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={14} data-testid="clinicAddress">
          {address}
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={14} data-testid="clinicAddress">
          {postcode}
        </Box>
        <Group justifyContent="space-between" data-testid="clinicFee">
          <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
            Clinic fee
          </Box>
          {clinicFee !== undefined && (
            <Currency symbol={currencySymbol} value={clinicFee} fontSize={18} />
          )}
        </Group>
      </Box>
      {clinicBooking.status === "incomplete" &&
        (clinicBooking?.url || clinicBooking?.location?.bookingLink) && (
          <Stack gap={20} my={20}>
            <ButtonV2
              mr="auto"
              color="green"
              onClick={() => {
                window.open(clinicBooking?.url || clinicBooking?.location?.bookingLink, "_blank");
              }}
            >
              Book your appointment &gt;
            </ButtonV2>
          </Stack>
        )}
      {clinicBooking.status === "pending" && (
        <Stack gap={20} my={20}>
          <Box fontSize={16} fontFamily="gilroyMedium">
            Booking confirmed: {formatBookingDatetime(clinicBooking.details?.datetime)}
          </Box>
          <Group flexWrap="wrap" gap={10}>
            <ButtonV2
              color="green"
              onClick={() => {
                window.open(
                  `mailto:support@omnos.me?subject=Appointment%20reschedule%20request%20-%20${orderId} (${clinicBooking.orderTestItemId})`,
                  "_blank"
                );
              }}
              rightIcon={<ChevronComponent />}
            >
              Reschedule
            </ButtonV2>
            <ButtonV2
              color="red"
              onClick={() => {
                window.open(
                  `mailto:support@omnos.me?subject=Appointment%20cancel%20request%20-%20${orderId}  (${clinicBooking.orderTestItemId})`,
                  "_blank"
                );
              }}
              rightIcon={<ChevronComponent />}
            >
              Cancel
            </ButtonV2>
          </Group>
        </Stack>
      )}
      {clinicBooking.status === "cancelled" && (
        <Stack gap={20} my={20}>
          <Box fontSize={16} fontFamily="gilroyMedium">
            Booking cancelled: {formatBookingDatetime(clinicBooking.details?.datetime)}
          </Box>
        </Stack>
      )}
      {clinicBooking.status === "complete" && (
        <Stack gap={20} my={20}>
          <Box fontSize={16} fontFamily="gilroyMedium">
            Booking complete: {formatBookingDatetime(clinicBooking.details?.datetime)}
          </Box>
        </Stack>
      )}
    </>
  );
}

export function OrderDetailsContent({
  id,
  updated,
  checkoutDate,
  derivedPaymentStatus,
  derivedProgressStatus,
  user,
  shippingFullName,
  shippingAddress1,
  shippingAddress2,
  shippingTownCity,
  shippingPostalCode,
  shippingCountry,
  testItems,
  supplementItems,
  postageCosts,
  discountedTotal,
  totalDiscount,
  vat,
  refunded,
  sourceBasket,
  status
}) {
  const showPrices = !sourceBasket || (sourceBasket && sourceBasket.payee === BASKET_PAYEE_PATIENT);
  // VAT is only paid on consumer orders
  const showVat = !sourceBasket;

  let fullDisplayPrice = 0;
  if (sourceBasket) {
    fullDisplayPrice = sourceBasket.basketTotalPrice;
  } else {
    fullDisplayPrice = discountedTotal + postageCosts;
  }

  let shippingDisplayPrice = 0;
  if (sourceBasket) {
    shippingDisplayPrice = sourceBasket.basketShippingCost;
  } else {
    shippingDisplayPrice = postageCosts;
  }

  let displayCurrencySymbol = sourceBasket?.currencySymbol || "£";

  return (
    <>
      {sourceBasket && sourceBasket?.practitioner?.user?.fullName ? (
        <Box px={20} py={20}>
          <Box maxWidth={860} mx="auto">
            <Box fontFamily="gilroyMedium" fontSize={18} data-testid="orderedBy">
              {`Ordered by: ${sourceBasket.practitioner.user.fullName}`}
            </Box>
          </Box>
        </Box>
      ) : null}
      <PanelBoxV2
        maxWidth={860}
        outer={{
          bg: "haze",
          py: 20,
          px: 20
        }}
      >
        <Box py={20}>
          <OrderDetails
            showPaymentStatus={showPrices}
            id={id}
            orderNo={id}
            orderDate={checkoutDate}
            shippingFullName={shippingFullName}
            user={user}
            derivedPaymentStatus={derivedPaymentStatus}
            derivedProgressStatus={derivedProgressStatus}
            sourceBasket={sourceBasket}
            status={status}
          />
        </Box>
        <Divider />
        <Stack py={20}>
          <Box fontFamily="gilroyBold" fontSize={16}>
            Shipping address
          </Box>
          <Stack fontFamily="gilroyMedium" fontSize={14}>
            {shippingFullName && (
              <span
                fontFamily="inherit"
                fontSize="inherit"
                style={{
                  wordBreak: "break-word",
                  wordWrap: "anywhere"
                }}
                data-component-name="ShippingFullName"
              >
                {shippingFullName}
              </span>
            )}
            {shippingAddress1 && (
              <span
                fontFamily="inherit"
                fontSize="inherit"
                style={{
                  wordBreak: "break-word",
                  wordWrap: "anywhere"
                }}
                data-component-name="ShippingAddress1"
              >
                {shippingAddress1}
              </span>
            )}
            {shippingAddress2 && (
              <span
                fontFamily="inherit"
                fontSize="inherit"
                style={{
                  wordBreak: "break-word",
                  wordWrap: "anywhere"
                }}
                data-component-name="ShippingAddress2"
              >
                {shippingAddress2}
              </span>
            )}
            {shippingTownCity && (
              <span
                fontFamily="inherit"
                fontSize="inherit"
                style={{
                  wordBreak: "break-word",
                  wordWrap: "anywhere"
                }}
                data-component-name="ShippingTownCity"
              >
                {shippingTownCity}
              </span>
            )}
            {shippingPostalCode && (
              <span
                fontFamily="inherit"
                fontSize="inherit"
                style={{
                  wordBreak: "break-word",
                  wordWrap: "anywhere"
                }}
                data-component-name="ShippingPostalCode"
              >
                {shippingPostalCode}
              </span>
            )}
            {shippingCountry && (
              <span
                fontFamily="inherit"
                fontSize="inherit"
                style={{
                  wordBreak: "break-word",
                  wordWrap: "anywhere"
                }}
                data-component-name="ShippingCountry"
              >
                {shippingCountry}
              </span>
            )}
          </Stack>
        </Stack>
      </PanelBoxV2>
      <PanelBoxV2
        maxWidth={860}
        outer={{
          pt: [30, 30, 60],
          pb: [60, 60, 120],
          px: 20,
          "data-component-name": "OrderDetailItems"
        }}
        stacked
        gap={20}
      >
        {testItems.map(ti => {
          if (ti.clinicLocation) {
            return (
              <Stack
                key={ti.id}
                gap={20}
                pb={20}
                borderBottomColor="midGrey"
                borderBottomStyle="solid"
                borderBottomWidth={1}
                data-testid="lineInOrderDetail"
              >
                <WithClinicListItemCard
                  orderId={id}
                  price={
                    showPrices ? (ti.price - ti.clinicLocation.clinic.fee).toFixed(2) : undefined
                  }
                  clinicName={ti.clinicLocation.clinic.name || "Clinic"}
                  clinicFee={showPrices ? ti.clinicLocation.clinic.fee : undefined}
                  address={ti.clinicLocation.address}
                  postcode={ti.clinicLocation.postCode}
                  name={ti.nameInBasket}
                  currencySymbol={displayCurrencySymbol}
                  clinicBooking={
                    ti.clinicBooking
                      ? {
                          id: ti.clinicBooking.id,
                          orderTestItemId: ti.id,
                          status: ti.clinicBooking.status,
                          url: ti.clinicBooking.url,
                          details: ti.clinicBooking.details,
                          location: ti.clinicLocation
                        }
                      : {
                          status: "open",
                          url: ti.clinicLocation.bookingLink,
                          orderTestItemId: ti.id,
                          location: ti.clinicLocation
                        }
                  }
                  orderTestItem={ti}
                />
              </Stack>
            );
          }
          return (
            <ListItemCard
              key={ti.id}
              price={showPrices ? ti.price : undefined}
              name={ti.nameInBasket}
              orderTestItem={ti}
              styles={{
                pb: 20,
                borderBottomColor: "midGrey",
                borderBottomStyle: "solid",
                borderBottomWidth: 1,
                "data-testid": "lineInOrderDetail"
              }}
              currencySymbol={displayCurrencySymbol}
            />
          );
        })}
        {supplementItems.map(si => (
          <ListItemCard
            key={si.id}
            name={si.nameInBasket}
            price={showPrices ? si.price : undefined}
            orderTestItem={si}
            currencySymbol={displayCurrencySymbol}
            styles={{
              "data-testid": "lineInOrderDetail"
            }}
          />
        ))}
        <Spacer py={20} />
        {showPrices ? (
          <Stack gap={10} alignItems="flex-end">
            {!!totalDiscount && (
              <Currency
                symbol={displayCurrencySymbol}
                prefix="DISCOUNT"
                value={-1 * totalDiscount}
                fontSize={28}
                data-testid="discountTotal"
              />
            )}
            <Currency
              symbol={displayCurrencySymbol}
              prefix="SHIPPING"
              value={shippingDisplayPrice}
              fontSize={28}
              data-testid="shippingTotal"
            />
            <Currency
              symbol={displayCurrencySymbol}
              prefix="VAT"
              value={showVat ? vat : 0}
              fontSize={28}
              data-testid="vatTotal"
            />
            <Currency
              symbol={displayCurrencySymbol}
              prefix="TOTAL"
              value={fullDisplayPrice.toFixed(2)}
              fontSize={[28, 28, 44]}
              data-testid="orderTotal"
            />
            {!!refunded && (
              <Currency
                symbol={displayCurrencySymbol}
                prefix="REFUNDED"
                value={refunded}
                fontSize={28}
                data-testid="refundTotal"
                color="red"
              />
            )}
          </Stack>
        ) : (
          <Stack alignItems="flex-end" gap={0} data-testid="orderPlacedBy">
            <Box fontFamily="gilroyBold" fontSize={24} textTransform="uppercase">
              order placed by:
            </Box>
            <Box
              fontFamily="gilroyBold"
              fontSize={24}
              textTransform="uppercase"
              data-testid="orderPlacedBy:name"
            >
              {sourceBasket.organisation?.name || sourceBasket.practitioner?.user?.fullName}
            </Box>
          </Stack>
        )}
      </PanelBoxV2>
    </>
  );
}

function OrderNotFound() {
  return (
    <Page bg="haze" header={<Jumbotron title="Order" />}>
      <NothingToShow
        jumbotron={
          <>
            <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
              Not found
            </Box>
            <Box py={[2, 2, 20]} />
            <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
              I'm sorry, we can't seem to find that order. Please contact{" "}
              <ExternalTextLink href="mailto:support@omnos.me">support@omnos.me</ExternalTextLink>
            </Box>
          </>
        }
      />
    </Page>
  );
}

function EmptyCheckout() {
  return (
    <CustomerPage
      bg="haze"
      jumbotronProps={{
        title: "Order"
      }}
    >
      <NothingToShow
        jumbotron={
          <>
            <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
              You have no items in your basket
            </Box>
          </>
        }
      />
    </CustomerPage>
  );
}

function ChargelessCheckoutComplete({ order }) {
  return (
    <CheckoutTemplate title="Order Detail" gap={0}>
      <OrderDetailsContent {...order} />
    </CheckoutTemplate>
  );
}

function ChargelessCheckout({ order }) {
  const { user } = useAuthContext();

  return (
    <CheckoutTemplate title="Chargeless Checkout">
      {user ? (
        <AuthenticatedUserCheckoutFormProvider order={order}>
          <Stack gap={[20, 20, 40]} data-component-name="BasketSummary">
            <PostageCostsProvider order={order}>
              <PostageCostsContext.Consumer>
                {({ element }) => (
                  <UserBasketSummary
                    beforeTotals={
                      <>
                        <>
                          {order.totalDiscount !== undefined && order.totalDiscount > 0 && (
                            <Currency
                              prefix="DISCOUNT"
                              value={-1 * order.totalDiscount}
                              fontSize={28}
                              data-testid="discountTotal"
                              color="red"
                            />
                          )}
                        </>
                        {element}
                      </>
                    }
                    afterTotals={<TotalWithPostage order={order} />}
                  />
                )}
              </PostageCostsContext.Consumer>
            </PostageCostsProvider>
          </Stack>
          <AuthenticatedChargelessCheckoutForm />
        </AuthenticatedUserCheckoutFormProvider>
      ) : (
        <GuestCheckoutFormProvider order={order}>
          <Stack gap={[20, 20, 40]} data-component-name="BasketSummary">
            <PostageCostsProvider order={order}>
              <PostageCostsContext.Consumer>
                {({ element }) => (
                  <UserBasketSummary
                    beforeTotals={
                      <>
                        <>
                          {order.totalDiscount !== undefined && order.totalDiscount > 0 && (
                            <Currency
                              prefix="DISCOUNT"
                              value={-1 * order.totalDiscount}
                              fontSize={28}
                              data-testid="discountTotal"
                              color="red"
                            />
                          )}
                        </>
                        {element}
                      </>
                    }
                    afterTotals={<TotalWithPostage order={order} />}
                  />
                )}
              </PostageCostsContext.Consumer>
            </PostageCostsProvider>
          </Stack>
          <GuestChargelessCheckoutForm />
        </GuestCheckoutFormProvider>
      )}
    </CheckoutTemplate>
  );
}

function StripeCheckoutComplete({ order }) {
  return (
    <CheckoutTemplate title="Order Detail" gap={0}>
      <OrderDetailsContent {...order} />
    </CheckoutTemplate>
  );
}

function StripePayment({ order }) {
  const { user } = useAuthContext();

  return (
    <CheckoutTemplate title="Checkout">
      <Stack gap={[20, 20, 40]} data-component-name="OrderSummary">
        <OrderSummary order={order} title="Basket Summary" />
      </Stack>
      {user ? (
        <DataLoader
          query={PLATFORM_USER_PROFILE_QUERY}
          render={({ platformUserProfile }) => (
            <Stack gap={40}>
              <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                Payment details
              </Box>
              <StripePaymentFormContent order={order} platformUserProfile={platformUserProfile} />
            </Stack>
          )}
        />
      ) : (
        <Stack gap={40}>
          <Box as="h2" fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
            Payment details
          </Box>
          <StripePaymentFormContent order={order} />
        </Stack>
      )}
    </CheckoutTemplate>
  );
}

function StripeCheckout({ order }) {
  const { user } = useAuthContext();

  return user ? (
    <AuthenticatedUserCheckoutFormProvider order={order}>
      <PostageCostsProvider order={order}>
        <PostageCostsContext.Consumer>
          {({ element }) => (
            <CheckoutTemplate
              title="Checkout"
              discount={
                order.totalDiscount !== undefined &&
                order.totalDiscount > 0 && (
                  <Currency
                    prefix="DISCOUNT"
                    value={-1 * order.totalDiscount}
                    fontSize={28}
                    data-testid="discountTotal"
                    color="red"
                  />
                )
              }
              shipping={element}
              total={<TotalWithPostage order={order} />}
              showBasketSummaryFooter
            >
              <CheckoutContext.Consumer>
                {({ basketSummaryRef }) => (
                  <>
                    <Stack
                      gap={[20, 20, 40]}
                      data-component-name="BasketSummary"
                      ref={basketSummaryRef}
                    >
                      <PostageCostsContext.Consumer>
                        {({ element }) => (
                          <UserBasketSummary
                            beforeTotals={
                              <>
                                <>
                                  {order.totalDiscount !== undefined && order.totalDiscount > 0 && (
                                    <Currency
                                      prefix="DISCOUNT"
                                      value={-1 * order.totalDiscount}
                                      fontSize={28}
                                      data-testid="discountTotal"
                                      color="red"
                                    />
                                  )}
                                </>
                                {element}
                              </>
                            }
                            afterTotals={<TotalWithPostage order={order} />}
                          />
                        )}
                      </PostageCostsContext.Consumer>
                    </Stack>
                    <AuthenticatedCheckoutForm />
                  </>
                )}
              </CheckoutContext.Consumer>
            </CheckoutTemplate>
          )}
        </PostageCostsContext.Consumer>
      </PostageCostsProvider>
    </AuthenticatedUserCheckoutFormProvider>
  ) : (
    <GuestCheckoutFormProvider order={order}>
      <PostageCostsProvider order={order}>
        <PostageCostsContext.Consumer>
          {({ element }) => (
            <CheckoutTemplate
              title="Checkout"
              discount={
                order.totalDiscount !== undefined &&
                order.totalDiscount > 0 && (
                  <Currency
                    prefix="DISCOUNT"
                    value={-1 * order.totalDiscount}
                    fontSize={28}
                    data-testid="discountTotal"
                    color="red"
                  />
                )
              }
              shipping={element}
              total={<TotalWithPostage order={order} />}
              showBasketSummaryFooter
            >
              <CheckoutContext.Consumer>
                {({ basketSummaryRef }) => (
                  <>
                    <Stack
                      gap={[20, 20, 40]}
                      data-component-name="BasketSummary"
                      ref={basketSummaryRef}
                    >
                      <PostageCostsContext.Consumer>
                        {({ element }) => (
                          <UserBasketSummary
                            beforeTotals={
                              <>
                                <>
                                  {order.totalDiscount !== undefined && order.totalDiscount > 0 && (
                                    <Currency
                                      prefix="DISCOUNT"
                                      value={-1 * order.totalDiscount}
                                      fontSize={28}
                                      data-testid="discountTotal"
                                      color="red"
                                    />
                                  )}
                                </>
                                {element}
                              </>
                            }
                            afterTotals={<TotalWithPostage order={order} />}
                          />
                        )}
                      </PostageCostsContext.Consumer>
                    </Stack>
                    <GuestCheckoutForm />
                  </>
                )}
              </CheckoutContext.Consumer>
            </CheckoutTemplate>
          )}
        </PostageCostsContext.Consumer>
      </PostageCostsProvider>
    </GuestCheckoutFormProvider>
  );
}

function Order({ order }) {
  if (order.sourceBasket) {
    // If an order has a source basket it means it was created via the practitioner shop
    // Such orders should not be paid via this flow
    // So only show the read only order detail view
    return (
      <OrderDetailTemplate title="Order Detail" gap={0}>
        <OrderDetailsContent {...order} />
      </OrderDetailTemplate>
    );
  }

  if (order.testItems.length === 0 && order.supplementItems.length === 0) {
    return <EmptyCheckout />;
  }
  if (order.discountedTotal + order.postageCosts === 0) {
    if (order.status === "Chargeless checkout complete") {
      return <ChargelessCheckoutComplete order={order} />;
    } else {
      return <ChargelessCheckout order={order} />;
    }
  } else {
    if (order.status === "Payment succeeded") {
      return <StripeCheckoutComplete order={order} />;
    } else if (order.status === "Payment initiated") {
      return <StripePayment order={order} />;
    } else {
      return <StripeCheckout order={order} />;
    }
  }
}

export default function OrderDetailPage({
  match: {
    params: { id }
  }
}) {
  return (
    <DataLoader
      query={ORDER_QUERY}
      variables={{
        id
      }}
      render={({ order }) => (order ? <Order order={order} /> : <OrderNotFound />)}
    />
  );
}
