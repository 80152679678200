const { default: Box } = require("components/Box");
const { default: Checkbox } = require("tpo/Checkbox");
const { default: Group } = require("tpo/Group");
const { default: Stack } = require("tpo/Stack");
const { default: ButtonV2 } = require("v2/Buttons");

export default function TopicFilters({ filters, setFilters, topics }) {
  return (
    <Stack
      gap={40}
      border="1px solid black"
      p={20}
      pb={60}
      borderRadius={20}
      minWidth={310}
      gridColumn="2 / 3"
    >
      <Group justifyContent="space-between">
        <Box fontFamily="gilroyBold" fontSize={32}>
          Filter
        </Box>
        <ButtonV2
          variant="link"
          textTransform="unset"
          textDecoration="underline"
          letterSpacing={0}
          sx={{
            fontSize: 16,
            fontFamily: "gilroyRegular"
          }}
          onClick={() => {
            setFilters(new Set([]));
          }}
        >
          Reset
        </ButtonV2>
      </Group>
      <Stack gap={16}>
        <Box
          fontFamily="gilroyBold"
          color="midGrey"
          letterSpacing={2.8}
          fontSize={12}
          textTransform="uppercase"
          mb={2}
        >
          Topics
        </Box>
        {topics?.map(topic => (
          <Checkbox
            key={topic.id}
            label={topic.name}
            styles={{
              checkbox: {
                size: "sm"
              }
            }}
            checked={filters.has(topic.id)}
            onChange={e => {
              const checked = e.target.checked;
              if (checked) {
                setFilters(new Set([...filters, topic.id]));
              } else {
                setFilters(() => {
                  const newFilters = new Set(filters);
                  newFilters.delete(topic.id);
                  return newFilters;
                });
              }
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
}
