import Circle from "components/Circle";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { theme } from "core/theme";
import { WEBINAR_CONNECTIONS_QUERY } from "graphql/knowledgeCenter/webinars";
import PlayIcon from "images/PlayIcon";

import ListTemplate from "./ListTemplate";
import WebinarCard from "./WebinarCard";

export default function Webinars() {
  const { organisation } = useOrganisationContext();
  const { basket } = useOrganisationBasketContext();

  return (
    <ListTemplate
      defaultSort="-start_time"
      query={WEBINAR_CONNECTIONS_QUERY}
      connectionName="webinarConnections"
      objectsPlural="webinars"
      Card={WebinarCard}
      pageDescription="Sign up to upcoming or re-watch our full archive of webinar sessions. "
      pageTitle="Webinars"
      icon={
        <Circle bg="transparent" size={80} border="2px solid black">
          <PlayIcon fill={theme.colors.dark} size={40} />
        </Circle>
      }
      filtersTitle="Webinar Filters"
      queryVariables={{
        paymentIsoCode: basket?.paymentLocale?.isoCode,
        organisation: organisation?.id
      }}
    />
  );
}
