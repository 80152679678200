import { useCallback, useEffect } from "react";

import { useLocation, useHistory } from "react-router-dom";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { useLazyQuery, useQuery } from "@apollo/client";
import { useAppState } from "components/AppStateProvider";
import Box from "components/Box";
import DataLoader from "components/DataLoader";
import Loading from "components/Loading";
import ProductFilters from "components/ProductFilters";
import OrganisationTabs from "components/organisations/OrganisationTabs";
import { useLocalesContext } from "contexts/Locale";
import { SupplementsProvider } from "contexts/SupplementContext";
import { useTestProductModalContext } from "contexts/TestProductModalContext";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { OUT_OF_STOCK } from "core/constants";
import { theme } from "core/theme";
import { ERROR_404_URL } from "core/urls";
import {
  PRACTITIONER_TEST_PRODUCT_CONNECTIONS_QUERY,
  PRACTITIONER_TEST_PRODUCT_DETAIL_QUERY,
  SHOP_LISTING_FILTERS_QUERY
} from "graphql/shop";
import { useDebounce } from "hooks/useDebounce";
import useListControls from "hooks/useListControls";
import Badge from "tpo/Badge";
import { PanelBoxV2 } from "tpo/Boxes";
import Group from "tpo/Group";
import InfiniteScroll, { InfiniteScrollContext } from "tpo/InfiniteScroll";
import SortMenu from "tpo/SortMenu";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import { Search } from "tpo/TextInput";
import TestProductListCard from "tpo/partnerDashboard/practitioner/TestProductListCard";
import ListPageTemplate from "tpo/shop/ListPageTemplate";
import {
  SupplementControls,
  SupplementDetailModal,
  SupplementList
} from "tpo/shop/SupplementListPage";
import useProductFilters from "tpo/shop/hooks/useProductFilters";
import useTestProducts from "tpo/shop/hooks/useTestProducts";

import LocaleSwitcher from "./ui/LocaleSwitcher";

function TestProductListContent({ sampleTypes, categories, testingServices }) {
  const history = useHistory();
  const location = useLocation();

  const { locale } = useLocalesContext();
  const { organisation } = useOrganisationContext();
  const search = new URLSearchParams(location.search).get("search") || "";

  const debouncedSearch = useDebounce(search, 200);

  const controls = useListControls({
    sortOptions: [
      {
        label: "Recommended",
        value: "sort_order"
      },
      {
        label: "A - Z",
        value: "name"
      },
      {
        label: "Z - A",
        value: "-name"
      },
      {
        label: "Price Asc",
        value: "trade_current_price"
      },
      {
        label: "Price Desc",
        value: "-trade_current_price"
      }
    ]
  });

  const { toggleFilter, allFilters, selectedFilters, validatedFilters } = useProductFilters({
    defaultFilters: {
      gender: "all",
      categories: [],
      sampleTypes: [],
      testingServices: []
    },
    allFilters: {
      gender: ["all", "female", "male"],
      testingServices: [...testingServices].sort(),
      categories: [...categories].sort(),
      sampleTypes: [...sampleTypes].sort()
    },
    filterSelected: {
      gender: ["male", "female"]
    }
  });

  const {
    addProductToBasket,
    basket,
    hasBasketGotProduct,
    removeProductFromBasket
  } = useOrganisationBasketContext();

  const {
    products: { items, hasMore, endCursor },
    loading,
    fetchMore
  } = useTestProducts({
    query: PRACTITIONER_TEST_PRODUCT_CONNECTIONS_QUERY,
    queryVariables: {
      organisation: organisation?.id
    },
    connectionsFieldName: "practitionerTestProductConnections",
    validatedFilters,
    search: debouncedSearch,
    sort: controls.sort,
    isoCode: locale?.isoCode,
    paymentIsoCode: basket?.paymentLocale?.isoCode,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const { selectTestProductId } = useTestProductModalContext();
  const { setPractitionerBasketOpen: setBasketOpen } = useAppState();

  const handleAddToBasket = useCallback(
    testProduct => {
      if (testProduct.options?.length || (!basket.purchaseAsStock && testProduct.addons?.length)) {
        selectTestProductId(testProduct.id);
        return;
      }
      addProductToBasket(testProduct.id);
      selectTestProductId(null);
      setBasketOpen(true);
    },
    [addProductToBasket, selectTestProductId, setBasketOpen, basket]
  );

  const handleRemoveFromBasket = useCallback(
    testProduct => {
      const line = basket.productLineItems.find(line => line.product.id === testProduct.id);
      // clinic location should not be present within the context this function is called
      // but add anyway to be explicit
      removeProductFromBasket(line.product.id, line.productOption?.id, line.clinicLocation?.id);
    },
    [removeProductFromBasket, basket]
  );

  return (
    <ListPageTemplate
      // TODO - use the standard url approach involving core.urls
      urlMap={{
        supplements: "/partners/dashboard/shop/supplements",
        tests: "/partners/dashboard/shop/tests"
      }}
      bg="haze"
      tab="tests"
      title="Find a matching test"
      subtitle="To ensure you see the correct tests available for your patient, please select the country where your patient is located. This will help you view and order the tests that are available and compliant with the regulations in their region."
      pb={[30, 30, 60]}
    >
      <PanelBoxV2
        maxWidth={1220}
        outer={{
          bg: "haze",
          pb: theme.spacing.section.pb,
          px: [20, 20, "5.5vw"]
        }}
      >
        <Stack gap={20}>
          <Box
            display="flex"
            flexDirection={["column", "column", "row"]}
            gap={20}
            justifyContent={[null, null, "space-between"]}
            flexWrap="wrap"
          >
            <Group gap={10}>
              <ProductFilters
                allFilters={allFilters}
                filtersTitle="Product Filters"
                drawerBg="dark"
                validatedFilters={validatedFilters}
                toggleFilter={toggleFilter}
              />
              <LocaleSwitcher />
            </Group>
            <Box display="flex" flexDirection={["column", "column", "row"]} gap={10}>
              <Search
                value={search}
                onChange={s => {
                  const searchParams = new URLSearchParams(location.search);
                  searchParams.set("search", s);
                  history.push({
                    path: location.path,
                    search: searchParams.toString()
                  });
                }}
                maxWidth={[null, null, 350]}
                minWidth={310}
                width="100%"
              />
              <Box display="flex" justifyContent="flex-end">
                <SortMenu
                  value={controls.sort}
                  setValue={controls.setSort}
                  open={controls.sortMenuOpen}
                  setOpen={controls.setSortMenuOpen}
                  options={controls.sortOptions}
                  placeholder="Sort By"
                />
              </Box>
            </Box>
          </Box>
          {selectedFilters}
        </Stack>
        <Spacer py={[2, 2, 20]} />
        <InfiniteScroll
          loader={<Loading />}
          hasMore={hasMore}
          loading={loading}
          next={() => {
            if (endCursor) {
              fetchMore({
                variables: {
                  after: endCursor,
                  first: 10
                }
              });
            }
          }}
          items={items}
        >
          <Stack>
            <InfiniteScrollContext.Consumer>
              {({ itemsList, setBottomElement }) => (
                <>
                  {itemsList.map((testProduct, idx) => {
                    let addText = "Add";
                    if (
                      testProduct.options?.length ||
                      (!basket?.purchaseAsStock && testProduct.addons?.length)
                    ) {
                      addText = "Select option";
                    } else if (!basket?.purchaseAsStock && hasBasketGotProduct(testProduct.id)) {
                      addText = "Added";
                    } else if (testProduct.status === OUT_OF_STOCK) {
                      addText = "Out of stock";
                    }
                    return (
                      <TestProductListCard
                        productCode={testProduct.id}
                        hasAddons={testProduct.addons?.length > 0}
                        centrifuge={testProduct.content.centrifuge}
                        addDisabled={
                          testProduct.status === OUT_OF_STOCK ||
                          (!basket?.purchaseAsStock && hasBasketGotProduct(testProduct.id))
                        }
                        title={testProduct.name}
                        subtitle={
                          !!testProduct.laboratory?.name && (
                            <Box
                              color="anchorBlue"
                              fontFamily="gilroyMedium"
                              fontSize={[16, 16, 18]}
                            >
                              {testProduct.laboratory.name}
                            </Box>
                          )
                        }
                        addText={addText}
                        pills={
                          <>
                            {testProduct.content.testType && (
                              <Badge bg="purple" color="white" size="xs">
                                {testProduct.content.testType}
                              </Badge>
                            )}
                            {testProduct.content.categories
                              .map(cat => cat.name)
                              .map(c => (
                                <Badge bg="haze" color="dark" size="xs" key={c}>
                                  {c}
                                </Badge>
                              ))}
                          </>
                        }
                        currencySymbol={basket?.currencySymbol}
                        tradeCurrentPrice={testProduct.tradeCurrentPrice}
                        tradeFullPrice={testProduct.tradeFullPrice}
                        rrpFullPrice={testProduct.exvatFullPrice}
                        key={testProduct.id}
                        onAdd={
                          basket?.purchaseAsStock ||
                          (!basket?.purchaseAsStock && !hasBasketGotProduct(testProduct.id))
                            ? e => {
                                e.onAdd = true;
                                if (
                                  basket?.purchaseAsStock ||
                                  !hasBasketGotProduct(testProduct.id)
                                ) {
                                  handleAddToBasket(testProduct);
                                }
                              }
                            : undefined
                        }
                        onRemove={
                          !basket?.purchaseAsStock && hasBasketGotProduct(testProduct.id)
                            ? e => {
                                e.onRemove = true;
                                handleRemoveFromBasket(testProduct);
                              }
                            : undefined
                        }
                        onSelect={e => {
                          if (e.onAdd || e.onRemove) return;
                          selectTestProductId(testProduct.id);
                        }}
                        ref={idx === itemsList.length - 1 ? setBottomElement : null}
                      />
                    );
                  })}
                </>
              )}
            </InfiniteScrollContext.Consumer>
          </Stack>
        </InfiniteScroll>
      </PanelBoxV2>
    </ListPageTemplate>
  );
}

function TestProductListPage() {
  const location = useLocation();
  const slug = new URLSearchParams(location.search).get("slug") || "";

  const { selectTestProductId } = useTestProductModalContext();
  const { basket } = useOrganisationBasketContext();
  const { organisation } = useOrganisationContext();

  const [fetchProduct] = useLazyQuery(PRACTITIONER_TEST_PRODUCT_DETAIL_QUERY, {
    onCompleted: data => {
      if (data?.practitionerTestProduct?.id) {
        selectTestProductId(data.practitionerTestProduct.id);
      }
    }
  });

  useEffect(() => {
    if (slug) {
      fetchProduct({
        variables: {
          slug,
          paymentIsoCode: basket?.paymentLocale?.isoCode,
          organisation: organisation?.id
        }
      });
    }
  }, [slug, fetchProduct, basket, organisation?.id]);

  return (
    <>
      <OrganisationTabs selectedTab="shop" />
      <DataLoader
        query={SHOP_LISTING_FILTERS_QUERY}
        variables={{
          includeFilters: ["Both", "B2B"]
        }}
        render={({ sampleTypes, categories, testingServices, userTestRecommendations }) => {
          return (
            <TestProductListContent
              sampleTypes={sampleTypes}
              categories={categories.map(cat => cat.name)}
              testingServices={testingServices}
              userTestRecommendations={userTestRecommendations}
            />
          );
        }}
      />
    </>
  );
}

function CmpSupplementList() {
  const { basket, addSupplementToBasket } = useOrganisationBasketContext();
  const { setPractitionerBasketOpen: setBasketOpen } = useAppState();

  const onAddToBasket = useCallback(
    ({ supplement }) => {
      addSupplementToBasket(supplement.id);
      setBasketOpen(true);
    },
    [addSupplementToBasket, basket]
  );

  return (
    <ListPageTemplate
      bg="haze"
      urlMap={{
        supplements: "/partners/dashboard/shop/supplements",
        tests: "/partners/dashboard/shop/tests"
      }}
      header={<OrganisationTabs selectedTab="shop" />}
      tab="supplements"
      title="Find a matching supplement"
      subtitle="We can get a huge amount of benefit from small changes to our diets. Alongside our nutritional advice we offer more specific, targeted support, in the form of carefully selected supplements. These supplements have been designed to be specific rather than general."
      pb={[30, 30, 60]}
    >
      <PanelBoxV2
        maxWidth={1220}
        outer={{
          bg: "haze",
          pb: theme.spacing.section.pb,
          px: [20, 20, "5.5vw"]
        }}
      >
        <SupplementControls />
        <SupplementList onAddToBasket={onAddToBasket} />
      </PanelBoxV2>
      <SupplementDetailModal onAddToBasket={onAddToBasket} />
    </ListPageTemplate>
  );
}

function SupplementListPage() {
  const { data, loading } = useQuery(SHOP_LISTING_FILTERS_QUERY);

  if (loading) {
    return <Loading />;
  }

  return (
    <SupplementsProvider
      dietryRestrictions={data?.dietryRestrictions}
      types={data?.types}
      brands={data?.brands}
      productFiltersDrawerBg="dark"
    >
      <CmpSupplementList />
    </SupplementsProvider>
  );
}

export default function OrganisationShop() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.path} exact>
        <Redirect to={`${match.path}/tests`} />
      </Route>
      <Route path={`${match.path}/supplements`} exact>
        <SupplementListPage />
      </Route>
      <Route path={`${match.path}/tests`} exact>
        <TestProductListPage />
      </Route>
      <Redirect to={ERROR_404_URL} />
    </Switch>
  );
}
