import { useQuery } from "@apollo/client";
import PartnerDashboardBox from "components/boxes/PartnerDashboardBox";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { SHIPPING_COUNTRIES_QUERY } from "graphql/shop";

import UnsupportedLocale from "./UnsupportedLocale";
import BusinessTemplate from "./ui/BusinessTemplate";
import StripeConnectSetupPanel from "./ui/StripeConnectSetupPanel";

export default function SetUp() {
  const { organisation } = useOrganisationContext();
  const { data } = useQuery(SHIPPING_COUNTRIES_QUERY);
  const shippingCountries = data?.shippingCountries;
  const unsupportedCountries =
    shippingCountries
      ?.filter(country => !country?.stripeSupported)
      ?.map(country => country.isoCode) || [];

  return (
    <BusinessTemplate active="payment" includeSubnav={false}>
      {unsupportedCountries.includes(organisation?.primaryAddress?.country?.isoCode) ? (
        <PartnerDashboardBox>
          <UnsupportedLocale country={organisation?.primaryAddress?.country} />
        </PartnerDashboardBox>
      ) : (
        <PartnerDashboardBox>
          <StripeConnectSetupPanel />
        </PartnerDashboardBox>
      )}
    </BusinessTemplate>
  );
}
