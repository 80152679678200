import { useQuery } from "@apollo/client";
import Box from "components/Box";
import Loading from "components/Loading";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { theme } from "core/theme";
import { ORGANISATION_ORDER_DETAIL_QUERY } from "graphql/organisations/queries/orders";
import { PanelBoxV2 } from "tpo/Boxes";
import Currency from "tpo/Currency";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";

import OrderListItem from "../ui/OrderListItem";
import OrganisationOrderSummary from "./OrganisationOrderSummary";

export default function OrganisationOrderDetails({ id }) {
  const { organisation } = useOrganisationContext();

  const { data, loading } = useQuery(ORGANISATION_ORDER_DETAIL_QUERY, {
    variables: {
      organisation: parseInt(organisation.id),
      id
    },
    skip: !organisation?.id
  });

  const order = data?.organisationOrder;
  const basket = data?.organisationOrder?.sourceBasket;

  const totalPrices = {
    discounted: basket?.basketTotalDiscount || 0,
    refunded: basket?.refunded || 0,
    shipping: basket?.basketShippingCost || 0,
    total: basket?.basketTotalPrice || 0,
    commission: basket?.basketTotalCommission || 0
  };

  if (basket?.refunded) {
    totalPrices.total = totalPrices.total - basket.refunded;
    let ratioRefunded = basket.refunded / basket.basketTotalPrice;
    totalPrices.commission = totalPrices.commission - totalPrices.commission * ratioRefunded;
  }

  if (loading || !order) {
    return <Loading />;
  }

  return (
    <Box
      bg="white"
      borderRadius={5}
      maxWidth={1280}
      pt={theme.spacing.section.pt}
      pb={theme.spacing.section.pb}
      px={[20, 20, 40]}
    >
      <Box maxWidth={1020} mx="auto">
        <PanelBoxV2
          maxWidth={860}
          outer={{
            px: 20,
            pb: [20, 20, 40]
          }}
        >
          <Box fontFamily="gilroyMedium" fontSize={18}>
            Ordered by:{" "}
            {loading || !basket
              ? "..."
              : `${basket?.practitioner?.user.firstName} ${basket?.practitioner?.user.lastName}`}
          </Box>
        </PanelBoxV2>
        <PanelBoxV2
          maxWidth={860}
          outer={{
            bg: "haze",
            py: 20,
            px: 20
          }}
        >
          <Box py={20}>
            <OrganisationOrderSummary order={order} />
          </Box>
          <Stack py={20}>
            <Box fontFamily="gilroyBold" fontSize={16}>
              Shipping details
            </Box>
            <Stack fontFamily="gilroyMedium" fontSize={14}>
              {!!order?.shippingFirstName && (
                <span
                  fontFamily="inherit"
                  fontSize="inherit"
                  style={{
                    wordBreak: "break-word",
                    wordWrap: "anywhere"
                  }}
                >
                  {order?.shippingFirstName}
                </span>
              )}
              {!!order?.shippingAddress1 && (
                <span
                  fontFamily="inherit"
                  fontSize="inherit"
                  style={{
                    wordBreak: "break-word",
                    wordWrap: "anywhere"
                  }}
                >
                  {order?.shippingAddress1}
                </span>
              )}
              {!!order?.shippingAddress2 && (
                <span
                  fontFamily="inherit"
                  fontSize="inherit"
                  style={{
                    wordBreak: "break-word",
                    wordWrap: "anywhere"
                  }}
                >
                  {order?.shippingAddress2}
                </span>
              )}
              {!!order?.shippingTownCity && (
                <span
                  fontFamily="inherit"
                  fontSize="inherit"
                  style={{
                    wordBreak: "break-word",
                    wordWrap: "anywhere"
                  }}
                >
                  {order?.shippingTownCity}
                </span>
              )}
              {!!order?.shippingPostalCode && (
                <span
                  fontFamily="inherit"
                  fontSize="inherit"
                  style={{
                    wordBreak: "break-word",
                    wordWrap: "anywhere"
                  }}
                >
                  {order?.shippingPostalCode}
                </span>
              )}
              {!!order?.shippingCountry && (
                <span
                  fontFamily="inherit"
                  fontSize="inherit"
                  style={{
                    wordBreak: "break-word",
                    wordWrap: "anywhere"
                  }}
                >
                  {order?.shippingCountry}
                </span>
              )}
            </Stack>
          </Stack>
        </PanelBoxV2>
        {basket && !loading && (
          <PanelBoxV2
            maxWidth={860}
            outer={{
              pt: [30, 30, 60],
              pb: [60, 60, 120],
              px: 20
            }}
          >
            <Stack gap={20}>
              {order.testItems.map(lineItem => (
                <OrderListItem
                  order={order}
                  orderTestItem={lineItem}
                  name={lineItem.nameInBasket}
                  registrationId={lineItem.providerTestId}
                  price={lineItem.price}
                  clinicLocation={lineItem.clinicLocation}
                  clinicBooking={lineItem.clinicBooking}
                  key={lineItem.id}
                  isAddOn={!!lineItem.parent}
                  currencySymbol={basket?.currencySymbol}
                />
              ))}
              {order.supplementItems.map(lineItem => (
                <OrderListItem
                  order={order}
                  orderTestItem={lineItem}
                  name={lineItem.nameInBasket}
                  price={lineItem.price}
                  key={lineItem.id}
                  currencySymbol={basket?.currencySymbol}
                />
              ))}
            </Stack>
            <Spacer py={20} />
            <Stack gap={10} alignItems="flex-end">
              {totalPrices.discounted > 0 && (
                <Currency
                  symbol={basket?.currencySymbol}
                  value={totalPrices.discounted * -1}
                  prefix={"DISCOUNT"}
                  fontSize={28}
                />
              )}
              {totalPrices.refunded > 0 && (
                <Currency
                  symbol={basket?.currencySymbol}
                  value={totalPrices.refunded * -1}
                  color="red"
                  prefix={"REFUNDED"}
                  fontSize={28}
                />
              )}
              <Currency
                prefix="SHIPPING"
                value={totalPrices.shipping}
                fontSize={28}
                symbol={basket?.currencySymbol}
              />
              <Currency
                prefix="TOTAL"
                value={totalPrices.total}
                fontSize={44}
                symbol={basket?.currencySymbol}
              />
              {basket.payee === "patient" && totalPrices.commission > 0 && (
                <Currency
                  prefix="COMMISSION"
                  value={totalPrices.commission}
                  fontSize={28}
                  symbol={basket?.currencySymbol}
                />
              )}
            </Stack>
          </PanelBoxV2>
        )}
      </Box>
    </Box>
  );
}
