import Circle from "components/Circle";
import Grid from "components/Grid";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { theme } from "core/theme";
import { COURSE_CONNECTIONS_QUERY } from "graphql/knowledgeCenter/webinars";
import PaperStackIcon from "images/PaperStackIcon";

import CourseCard from "./CourseCard";
import ListTemplate from "./ListTemplate";

export default function Courses() {
  const { organisation } = useOrganisationContext();
  const { basket } = useOrganisationBasketContext();

  return (
    <ListTemplate
      query={COURSE_CONNECTIONS_QUERY}
      connectionName="courseConnections"
      objectsPlural="courses"
      Card={CourseCard}
      pageDescription="Discover our online courses to help you get the most from the various tests we offer"
      pageTitle="Courses"
      icon={
        <Circle bg="transparent" size={80} border="2px solid black">
          <PaperStackIcon fill={theme.colors.dark} size={40} />
        </Circle>
      }
      filtersTitle="Course Filters"
      Container={Grid}
      containerProps={{
        gridTemplateColumns: ["repeat(auto-fill, minmax(310px, 1fr))"],
        pt: 40,
        pb: theme.spacing.section.pb
      }}
      sortOptions={[
        {
          label: "Title asc",
          value: "title"
        },
        {
          label: "Title desc",
          value: "-title"
        }
      ]}
      queryVariables={{
        paymentIsoCode: basket?.paymentLocale?.isoCode,
        organisation: organisation?.id
      }}
    />
  );
}
