import { createContext, useContext } from "react";

import { useMutation } from "@apollo/client";
import Box from "components/Box";
import { UNLOCK_WELLNESS_SCORES_MUTATION } from "graphql/accounts";
import Center from "tpo/Center";
import Stack from "tpo/Stack";
import WellnessScore from "tpo/WellnessScore";
import ButtonV2 from "v2/Buttons";

export const BlurredOverlayContext = createContext();

export function BlurredOverlayProvider({ children, show = false }) {
  return (
    <BlurredOverlayContext.Provider value={{ show }}>{children}</BlurredOverlayContext.Provider>
  );
}

export function BlurredOverlay({ center, maxWidth, px }) {
  const ctx = useContext(BlurredOverlayContext);

  const [unlockWellnessScoresMutation] = useMutation(UNLOCK_WELLNESS_SCORES_MUTATION);

  if (!ctx || !ctx?.show) return null;

  return (
    <Box top={0} right={0} bottom={0} left={0} zIndex={3} position="absolute">
      <Box
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        h="100%"
        style={{
          backdropFilter: "blur(10px)"
        }}
      />
      <Box
        position="absolute"
        top={200}
        left={center ? "50%" : 0}
        zIndex={10000}
        width="100%"
        maxWidth={maxWidth || null}
        style={{
          transform: center ? "translateX(-50%)" : "none"
        }}
        px={px}
      >
        <Box pt={60} pb={80} px={40} bg="blue" borderRadius={20}>
          <Stack gap={40} maxWidth={720} mx="auto" color="white" fontFamily="gilroyRegular">
            <Center>
              <WellnessScore
                // just static for this chart in the overlay
                wellnessScore={100}
                scoreInterpretations={[
                  {
                    name: "Attention Required",
                    score: 29,
                    __typename: "ScoreInterpretationType"
                  },
                  {
                    name: "Lets get started",
                    score: 30,
                    __typename: "ScoreInterpretationType"
                  },
                  {
                    name: "Keep improving!",
                    score: 60,
                    __typename: "ScoreInterpretationType"
                  },
                  {
                    name: "A Few Tweaks!",
                    score: 70,
                    __typename: "ScoreInterpretationType"
                  },
                  {
                    name: "Almost there!",
                    score: 80,
                    __typename: "ScoreInterpretationType"
                  },
                  {
                    name: "Optimal",
                    score: 90,
                    __typename: "ScoreInterpretationType"
                  }
                ]}
              />
            </Center>
            <Box color="white">
              <Box fontSize={24} color="inherit">
                Unlock valuable insights into your clients' functional test results, symptoms, and
                DNA analysis by opting in to view our wellness score analytics.
              </Box>
              <br />
              <br />
              <Box fontSize={18} color="inherit">
                Discover key health patterns and pinpoint areas for enhanced support. Our actionable
                suggestions are designed to enhance your clients' wellbeing journey.
              </Box>
              <br />
              <br />
              <Box fontSize={18} color="inherit">
                Ready to dive in? Click below to reveal the full analytics and elevate your practice
                with targeted, actionable insights!
              </Box>
            </Box>
            <Center>
              <ButtonV2
                color="green"
                size="sm"
                onClick={() => {
                  unlockWellnessScoresMutation();
                }}
              >
                unlock wellness scores
              </ButtonV2>
            </Center>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
