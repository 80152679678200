import useUserProfile from "hooks/useUserProfile";
import { FoodList } from "tpo/FoodsPage";

import { BlurredOverlayProvider } from "./BlurredOverlay";
import Template from "./shared/Template";

export default function FoodListRoute() {
  const { partnerUserProfile } = useUserProfile();

  return (
    <BlurredOverlayProvider show={!partnerUserProfile?.wellnessScoresUnlocked}>
      <Template selectedTab="food_list" docTitle="Patient's Food list">
        <FoodList />
      </Template>
    </BlurredOverlayProvider>
  );
}
