import { useCallback } from "react";

import { useAppState } from "components/AppStateProvider";
import Box from "components/Box";
import { useTestProductModalContext } from "contexts/TestProductModalContext";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { OUT_OF_STOCK } from "core/constants";
import Badge from "tpo/Badge";
import Stack from "tpo/Stack";
import TestProductListCard from "tpo/partnerDashboard/practitioner/TestProductListCard";

export default function FeaturedTests({ children, testProducts }) {
  const {
    basket,
    hasBasketGotProduct,
    addProductToBasket,
    removeProductFromBasket
  } = useOrganisationBasketContext();
  const { selectTestProductId } = useTestProductModalContext();
  const { setPractitionerBasketOpen: setBasketOpen } = useAppState();

  const handleAddToBasket = useCallback(
    testProduct => {
      if (testProduct.options?.length || (!basket.purchaseAsStock && testProduct.addons?.length)) {
        selectTestProductId(testProduct.id);
        return;
      }
      addProductToBasket(testProduct.id);
      selectTestProductId(null);
      setBasketOpen(true);
    },
    [addProductToBasket, selectTestProductId, setBasketOpen, basket]
  );

  const handleRemoveFromBasket = useCallback(
    testProduct => {
      const line = basket.productLineItems.find(line => line.product.id === testProduct.id);
      // clinic location should not be present within the context this function is called
      // but add anyway to be explicit
      removeProductFromBasket(line.product.id, line.productOption?.id, line.clinicLocation?.id);
    },
    [removeProductFromBasket, basket]
  );

  return (
    <Stack gap={20} pt={60} width="100%" maxWidth={720}>
      <Box fontFamily="gilroyBold" fontSize={[32, 32, 44]}>
        Featured Tests
      </Box>
      {children}
      {testProducts.map(tp => {
        let addText = "Add";
        if (tp.options?.length || (!basket?.purchaseAsStock && tp.addons?.length)) {
          addText = "Select option";
        } else if (!basket?.purchaseAsStock && hasBasketGotProduct(tp.id)) {
          addText = "Added";
        } else if (tp.status === OUT_OF_STOCK) {
          addText = "Out of stock";
        }

        return (
          <TestProductListCard
            borderRadius={20}
            withBorder
            productCode={tp.id}
            hasAddons={tp.addons?.length > 0}
            centrifuge={tp.content.centrifuge}
            addDisabled={
              tp.status === OUT_OF_STOCK || (!basket?.purchaseAsStock && hasBasketGotProduct(tp.id))
            }
            title={tp.name}
            subtitle={
              !!tp.laboratory?.name && (
                <Box color="anchorBlue" fontFamily="gilroyMedium" fontSize={[16, 16, 18]}>
                  {tp.laboratory.name}
                </Box>
              )
            }
            addText={addText}
            pills={
              <>
                {tp.content?.testType && (
                  <Badge bg="purple" color="white" size="xs">
                    {tp.content.testType}
                  </Badge>
                )}
                {tp.content?.categories
                  .map(cat => cat.name)
                  .map(c => (
                    <Badge bg="haze" color="dark" size="xs" key={c}>
                      {c}
                    </Badge>
                  ))}
              </>
            }
            currencySymbol={basket?.currencySymbol}
            tradeCurrentPrice={tp.tradeCurrentPrice}
            tradeFullPrice={tp.tradeFullPrice}
            rrpFullPrice={tp.exvatFullPrice}
            key={tp.id}
            onAdd={
              basket?.purchaseAsStock || (!basket?.purchaseAsStock && !hasBasketGotProduct(tp.id))
                ? e => {
                    e.onAdd = true;
                    if (basket?.purchaseAsStock || !hasBasketGotProduct(tp.id)) {
                      handleAddToBasket(tp);
                    }
                  }
                : undefined
            }
            onRemove={
              !basket?.purchaseAsStock && hasBasketGotProduct(tp.id)
                ? e => {
                    e.onRemove = true;
                    handleRemoveFromBasket(tp);
                  }
                : undefined
            }
            onSelect={e => {
              if (e.onAdd || e.onRemove) return;
              selectTestProductId(tp.id);
            }}
          />
        );
      })}
    </Stack>
  );
}
