import { useCallback, useEffect, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { PAYEE_PATIENT } from "components/organisations/constants";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { BASKET_INVALID_RESTRICTED_SHIPPING } from "core/constants";
import {
  ADD_ON_TO_PRODUCT_MUTATION,
  ADD_PRODUCT_TO_ORGANISATION_BASKET_MUTATION,
  ADD_SUPPLEMENT_TO_ORGANISATION_BASKET_MUTATION,
  CLEAR_BASKET_MUTATION,
  REMOVE_ADD_ON_FROM_PRODUCT_MUTATION,
  REMOVE_PRODUCT_FROM_ORGANISATION_BASKET_MUTATION,
  REMOVE_SUPPLEMENT_FROM_ORGANISATION_BASKET_MUTATION,
  SET_PRODUCT_QTY_IN_ORGANISATION_BASKET_MUTATION,
  SET_SUPPLEMENT_QTY_IN_ORGANISATION_BASKET_MUTATION,
  UPDATE_ORGANISATION_BASKET_DETAILS_MUTATION,
  APPLY_DISCOUNT_MUTATION,
  REMOVE_DISCOUNT_MUTATION
} from "graphql/organisations/mutations";
import { ORGANISATION_BASKET_DETAIL_QUERY } from "graphql/organisations/queries/organisation";

export default function useOrganisationBasket() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState(null);
  const [
    acknowledgedBasketInvalidShippingRestrictionModal,
    setAcknowledgedBasketInvalidShippingRestrictionModal
  ] = useState(false);

  const hasMultipleProductsError = formErrors?.find(
    error =>
      error.field === "_All__" &&
      error.messages.includes(
        "Cannot purchase multiple products in a single basket if not purchasing as stock.  This includes options which share the same product."
      )
  );

  // this error is generated server side when they have addons in the basket and they are trying to purchase as stock
  const hasAddonError = formErrors?.find(
    error => error.field === "_All__" && error.messages.includes("Cannot convert to a stock order.")
  );

  const hasStockPatientError = formErrors?.find(
    error =>
      error.field === "purchaseAsStock" &&
      error.messages.includes("Purchase as stock must be purchased by organisation")
  );

  const { organisation } = useOrganisationContext();

  const { data: { basket } = {}, refetch: refetchBasket } = useQuery(
    ORGANISATION_BASKET_DETAIL_QUERY,
    {
      variables: { organisation: parseInt(organisation?.id) },
      skip: !organisation,
      fetchPolicy: "network-only",
      onCompleted: () => {
        setLoading(false);
      },
      onError: error => {
        setError(error);
        setLoading(false);
      }
    },
    [organisation]
  );

  const hasProductNotSoldInLocaleErrorModal =
    basket?.invalid &&
    (basket?.productLineItems.some(l => l.invalidReason === BASKET_INVALID_RESTRICTED_SHIPPING) ||
      basket?.supplementLineItems.some(
        l => l.invalidReason === BASKET_INVALID_RESTRICTED_SHIPPING
      ));

  useEffect(() => {
    if (!hasProductNotSoldInLocaleErrorModal && acknowledgedBasketInvalidShippingRestrictionModal) {
      setAcknowledgedBasketInvalidShippingRestrictionModal(false);
    }
  }, [hasProductNotSoldInLocaleErrorModal, acknowledgedBasketInvalidShippingRestrictionModal]);

  function handleErrors(responseData) {
    Object.keys(responseData).forEach(key => {
      if (responseData[key].errors && responseData[key].errors.length) {
        setError(responseData[key].errors[0].messages[0]);
      }
    });
  }

  const [_addProductToBasket] = useMutation(ADD_PRODUCT_TO_ORGANISATION_BASKET_MUTATION, {
    onCompleted: data => {
      handleErrors(data);
      setLoading(false);
    },
    onError: error => {
      setError(error);
      setLoading(false);
    },
    refetchQueries: [
      {
        query: ORGANISATION_BASKET_DETAIL_QUERY,
        variables: { organisation: parseInt(organisation?.id) }
      }
    ]
  });

  const [discountErrors, setDiscountErrors] = useState([]);

  const [_applyDiscount] = useMutation(APPLY_DISCOUNT_MUTATION);
  const [_removeDiscount] = useMutation(REMOVE_DISCOUNT_MUTATION);

  const applyDiscount = useCallback(
    discountCode => {
      if (!basket || !organisation) {
        return;
      }
      _applyDiscount({
        variables: {
          input: {
            id: basket.id,
            organisation: parseInt(organisation.id),
            discountCode
          }
        }
      }).then(resp => {
        if (resp.data.applyDiscountMutation.errors.length) {
          setDiscountErrors(
            resp.data.applyDiscountMutation.errors.map(({ messages }) => messages[0])
          );
        } else {
          setDiscountErrors([]);
        }
      });
    },
    [_applyDiscount, organisation, basket]
  );

  const removeDiscount = useCallback(() => {
    if (!basket || !organisation) {
      return;
    }
    setDiscountErrors([]);
    _removeDiscount({
      variables: {
        input: {
          id: basket.id,
          organisation: parseInt(organisation.id)
        }
      }
    });
  }, [_removeDiscount, organisation, basket]);

  const [_setProductQtyInOrganisationBasket] = useMutation(
    SET_PRODUCT_QTY_IN_ORGANISATION_BASKET_MUTATION,
    {
      onCompleted: data => {
        handleErrors(data);
        setLoading(false);
      },
      onError: error => {
        setError(error);
        setLoading(false);
      }
    }
  );

  const [_setSupplementQtyInOrganisationBasket] = useMutation(
    SET_SUPPLEMENT_QTY_IN_ORGANISATION_BASKET_MUTATION,
    {
      onCompleted: data => {
        handleErrors(data);
        setLoading(false);
      },
      onError: error => {
        setError(error);
        setLoading(false);
      }
    }
  );

  const [_addOnToProductMutation] = useMutation(ADD_ON_TO_PRODUCT_MUTATION, {
    onCompleted: data => {
      handleErrors(data);
      setLoading(false);
    },
    onError: error => {
      setError(error);
      setLoading(false);
    },
    refetchQueries: [
      {
        query: ORGANISATION_BASKET_DETAIL_QUERY,
        variables: { organisation: parseInt(organisation?.id) }
      }
    ]
  });

  const [_removeProductFromBasket] = useMutation(REMOVE_PRODUCT_FROM_ORGANISATION_BASKET_MUTATION, {
    onCompleted: data => {
      handleErrors(data);
      setLoading(false);
    },
    onError: error => {
      setError(error);
      setLoading(false);
    },
    refetchQueries: [
      {
        query: ORGANISATION_BASKET_DETAIL_QUERY,
        variables: { organisation: parseInt(organisation?.id) }
      }
    ]
  });

  const [_clearBasketMutation] = useMutation(CLEAR_BASKET_MUTATION, {
    onCompleted: data => {
      handleErrors(data);
      setLoading(false);
      setFormErrors(null);
    },
    onError: error => {
      setError(error);
      setLoading(false);
    },
    refetchQueries: [
      {
        query: ORGANISATION_BASKET_DETAIL_QUERY,
        variables: { organisation: parseInt(organisation?.id) }
      }
    ]
  });

  const [_removeAddOnFromProductMutation] = useMutation(REMOVE_ADD_ON_FROM_PRODUCT_MUTATION, {
    onCompleted: data => {
      handleErrors(data);
      setLoading(false);
    },
    onError: error => {
      setError(error);
      setLoading(false);
    },
    refetchQueries: [
      {
        query: ORGANISATION_BASKET_DETAIL_QUERY,
        variables: { organisation: parseInt(organisation?.id) }
      }
    ]
  });

  const [_addSupplementToBasket] = useMutation(ADD_SUPPLEMENT_TO_ORGANISATION_BASKET_MUTATION, {
    onCompleted: data => {
      handleErrors(data);
      setLoading(false);
    },
    onError: error => {
      setError(error);
      setLoading(false);
    },
    refetchQueries: [
      {
        query: ORGANISATION_BASKET_DETAIL_QUERY,
        variables: { organisation: parseInt(organisation?.id) }
      }
    ]
  });

  const [_removeSupplementFromBasket] = useMutation(
    REMOVE_SUPPLEMENT_FROM_ORGANISATION_BASKET_MUTATION,
    {
      onCompleted: data => {
        handleErrors(data);
        setLoading(false);
      },
      onError: error => {
        setError(error);
        setLoading(false);
      },
      refetchQueries: [
        {
          query: ORGANISATION_BASKET_DETAIL_QUERY,

          variables: { organisation: parseInt(organisation?.id) }
        }
      ]
    }
  );

  const [_updateBasketDetails] = useMutation(UPDATE_ORGANISATION_BASKET_DETAILS_MUTATION, {
    onCompleted: data => {
      handleErrors(data);
      setLoading(false);
      setFormErrors(data?.updateBasketDetailsMutation?.errors || null);
    },
    onError: error => {
      setError(error);
      setLoading(false);
      setFormErrors(null);
    }
    // TODO - confirm working as expected then remove this commented out code
    // Shouldn't be any need to refetch the basket as it's in the mutation
    // response so will update the cache automatically
    // refetchQueries: [
    //   {
    //     query: ORGANISATION_BASKET_DETAIL_QUERY,
    //     variables: { organisation: parseInt(organisation?.id) }
    //   }
    // ]
  });

  const addProductToBasket = useCallback(
    (productId, productOptionId, clinicLocationId) => {
      setLoading(true);
      setError(null);

      _addProductToBasket({
        variables: {
          input: {
            id: basket.id,
            organisation: parseInt(organisation?.id),
            testProductCode: productId,
            testProductOption: productOptionId,
            clinicLocation: clinicLocationId
          }
        }
      });
    },
    [setError, setLoading, basket, organisation, _addProductToBasket]
  );

  const setProductQtyInOrganisationBasket = useCallback(
    ({ productId, productOptionId, clinicLocationId, quantity }) => {
      if (quantity === "") return;

      setLoading(true);
      setError(null);

      _setProductQtyInOrganisationBasket({
        variables: {
          input: {
            id: basket.id,
            organisation: parseInt(organisation?.id),
            testProduct: productId,
            testProductOption: productOptionId,
            clinicLocation: clinicLocationId,
            quantity: parseInt(quantity)
          }
        }
      });
    },
    [setError, setLoading, basket, organisation, _setProductQtyInOrganisationBasket]
  );

  const setSupplememtQtyInOrganisationBasket = useCallback(
    ({ supplementId, quantity }) => {
      if (quantity === "") return;

      setLoading(true);
      setError(null);

      _setSupplementQtyInOrganisationBasket({
        variables: {
          input: {
            id: basket.id,
            organisation: parseInt(organisation?.id),
            supplement: supplementId,
            quantity: parseInt(quantity)
          }
        }
      });
    },
    [setError, setLoading, basket, organisation, _setSupplementQtyInOrganisationBasket]
  );

  const addOnToProduct = useCallback(
    (productId, addOnId) => {
      setLoading(true);
      setError(null);

      _addOnToProductMutation({
        variables: {
          input: {
            id: basket.id,
            organisation: parseInt(organisation?.id),
            testProduct: productId,
            addon: addOnId
          }
        }
      });
    },
    [setError, setLoading, basket, organisation, _addOnToProductMutation]
  );

  const removeProductFromBasket = useCallback(
    (productId, productOptionId, clinicLocationId) => {
      setLoading(true);
      setError(null);

      _removeProductFromBasket({
        variables: {
          input: {
            id: basket.id,
            organisation: parseInt(organisation?.id),
            testProductCode: productId,
            testProductOption: productOptionId,
            clinicLocation: clinicLocationId
          }
        }
      });
    },
    [setError, setLoading, basket, organisation, _removeProductFromBasket]
  );

  const removeAddOnFromProduct = useCallback(
    (productId, addOnId) => {
      setLoading(true);
      setError(null);

      _removeAddOnFromProductMutation({
        variables: {
          input: {
            id: basket.id,
            organisation: parseInt(organisation?.id),
            testProduct: productId,
            addon: addOnId
          }
        }
      });
    },
    [setError, setLoading, basket, organisation, _removeAddOnFromProductMutation]
  );

  const clearBasket = useCallback(() => {
    setLoading(true);
    setError(null);

    _clearBasketMutation({
      variables: {
        input: {
          id: basket.id,
          organisation: parseInt(organisation?.id)
        }
      }
    });
  }, [setLoading, setError, _clearBasketMutation, basket, organisation]);

  const addSupplementToBasket = useCallback(
    supplementId => {
      setLoading(true);
      setError(null);

      _addSupplementToBasket({
        variables: {
          input: {
            id: basket.id,
            organisation: parseInt(organisation?.id),
            supplement: supplementId
          }
        }
      });
    },
    [setError, setLoading, basket, organisation, _addSupplementToBasket]
  );

  const removeSupplementFromBasket = useCallback(
    supplementId => {
      setLoading(true);
      setError(null);

      _removeSupplementFromBasket({
        variables: {
          input: {
            id: basket.id,
            organisation: parseInt(organisation?.id),
            supplement: supplementId
          }
        }
      });
    },
    [setError, setLoading, basket, organisation, _removeSupplementFromBasket]
  );

  const updateBasketDetails = useCallback(
    fields => {
      setLoading(true);
      setError(null);

      _updateBasketDetails({
        variables: {
          input: {
            id: basket.id,
            organisation: parseInt(organisation?.id),
            // default field values to prevent overwrite
            payee: basket.payee,
            purchaseAsStock: basket.payee === PAYEE_PATIENT ? false : basket.purchaseAsStock,
            patient: basket.patient?.pk,
            ...fields
          }
        }
      });
    },
    [setError, setLoading, basket, organisation, _updateBasketDetails]
  );

  const hasBasketGotProduct = useCallback(
    (productId, productOptionId) => {
      if (!basket) {
        return false;
      }
      if (productId && productOptionId) {
        return basket.productLineItems.some(
          productLineItem =>
            productLineItem.product.id === productId &&
            productLineItem.productOption?.id === productOptionId
        );
      }
      return basket.productLineItems.some(
        productLineItem => productLineItem.product.id === productId
      );
    },
    [basket]
  );

  const hasProductGotAddon = useCallback(
    (productId, addOnId) => {
      if (!basket) {
        return false;
      }
      const productLine = basket.productLineItems.find(
        productLineItem => productLineItem.product.id === productId
      );
      if (productLine) {
        return productLine.addons.some(addonLineItem => addonLineItem.product.id === addOnId);
      }
      return false;
    },
    [basket]
  );

  const getQtyForProduct = useCallback(
    (productId, productOptionId) => {
      if (!basket) {
        return undefined;
      }
      const productLine = basket.productLineItems.find(productLineItem => {
        if (productId && productOptionId) {
          return (
            productLineItem.product.id === productId &&
            productLineItem.productOption?.id === productOptionId
          );
        } else if (productId) {
          return productLineItem.product.id === productId;
        }
        return undefined;
      });
      if (productLine) {
        return productLine.quantity;
      }
      return undefined;
    },
    [basket]
  );

  const hasMultipleProducts = useCallback(() => {
    if (!basket) {
      return false;
    }

    return Object.values(
      basket.productLineItems.reduce((summary, productLineItem) => {
        if (productLineItem.product.id in summary) {
          summary[productLineItem.product.id] += productLineItem.quantity;
        } else {
          summary[productLineItem.product.id] = 1;
        }
        return summary;
      }, {})
    ).some(qty => qty > 1);
  }, [basket]);

  return {
    loading,
    error,
    basket,
    addProductToBasket,
    removeProductFromBasket,
    addSupplementToBasket,
    removeSupplementFromBasket,
    updateBasketDetails,
    addOnToProduct,
    hasBasketGotProduct,
    hasProductGotAddon,
    removeAddOnFromProduct,
    getQtyForProduct,
    hasMultipleProductsError: hasMultipleProductsError && !hasStockPatientError,
    setFormErrors,
    clearBasket,
    hasMultipleProducts,
    hasAddonError,
    hasStockPatientError,
    hasProductNotSoldInLocaleErrorModal,
    refetchBasket,
    setProductQtyInOrganisationBasket,
    setSupplememtQtyInOrganisationBasket,
    setAcknowledgedBasketInvalidShippingRestrictionModal,
    acknowledgedBasketInvalidShippingRestrictionModal,
    applyDiscount,
    removeDiscount,
    discountErrors
  };
}
