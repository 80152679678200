import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import Page from "components/Page";
import { ERROR_404_URL } from "core/urls";
import DashboardSwitcher from "tpo/DashboardSwitcher";

import Course from "./Course";
import Courses from "./Courses";
import Event from "./Event";
import Events from "./Events";
import FAQS from "./FAQS";
import Homepage from "./Homepage";
import SupportDocuments from "./SupportDocuments";
import VideoGuides from "./VideoGuides";
import Webinar from "./Webinar";
import Webinars from "./Webinars";

export default function KnowledgeBase() {
  const match = useRouteMatch();

  return (
    <Switch>
      {/* For now on prod only the webinars should show so keep this redirect */}
      <Route exact path={match.path}>
        <Redirect to={`${match.path}/webinars`} />
      </Route>
      <Route path={`${match.path}/homepage`} exact>
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <DashboardSwitcher bg="partners" isPartnerPage />
          <Homepage />
        </Page>
      </Route>
      <Route path={`${match.path}/courses`} exact>
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <DashboardSwitcher bg="partners" isPartnerPage />
          <Courses />
        </Page>
      </Route>
      <Route path={`${match.path}/courses/:slug`} exact>
        <Page bg="white" includeFooter addNavBarHeight={false}>
          <Course />
        </Page>
      </Route>
      <Route path={`${match.path}/events`} exact>
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <DashboardSwitcher bg="partners" isPartnerPage />
          <Events />
        </Page>
      </Route>
      <Route path={`${match.path}/events/:slug`} exact>
        <Page bg="white" includeFooter addNavBarHeight={false}>
          <Event />
        </Page>
      </Route>
      <Route path={`${match.path}/faqs`} exact>
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <DashboardSwitcher bg="partners" isPartnerPage />
          <FAQS />
        </Page>
      </Route>
      <Route path={`${match.path}/support_documents`} exact>
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <DashboardSwitcher bg="partners" isPartnerPage />
          <SupportDocuments />
        </Page>
      </Route>
      <Route path={`${match.path}/webinars`} exact>
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <DashboardSwitcher bg="partners" isPartnerPage />
          <Webinars />
        </Page>
      </Route>
      <Route path={`${match.path}/webinars/:slug`} exact>
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <Webinar />
        </Page>
      </Route>
      <Route path={`${match.path}/video_guides`} exact>
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <DashboardSwitcher bg="partners" isPartnerPage />
          <VideoGuides />
        </Page>
      </Route>
      <Redirect to={ERROR_404_URL} />
    </Switch>
  );
}
