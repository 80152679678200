import { forwardRef } from "react";

import { useHistory } from "react-router-dom";

import Box from "components/Box";
import { getCourseUrl } from "core/urls";
import PaperStackIcon from "images/PaperStackIcon";
import Center from "tpo/Center";
import Stack from "tpo/Stack";

const VideoGuideCard = forwardRef(({ bg, title, slug, shortDescription, thumbnailUrl }, ref) => {
  const history = useHistory();

  return (
    <Stack
      bg={bg}
      ref={ref}
      gap={0}
      borderRadius={5}
      style={{
        cursor: "pointer",
        overflow: "hidden"
      }}
      onClick={() => {
        history.push(getCourseUrl(slug));
      }}
    >
      {thumbnailUrl ? (
        <Box
          minHeight={300}
          style={{
            backgroundImage: `url(${thumbnailUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover"
          }}
        />
      ) : (
        <Center
          stacked
          minHeight={300}
          style={{
            backgroundColor: "white" // what should the fallback be if we have no thumbnail url?
          }}
        >
          <PaperStackIcon size={80} fill={"black"} />
        </Center>
      )}

      <Stack bg="white" gap={40} padding={[20, 20, 28]} flexGrow={1}>
        <Stack gap={8}>
          <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]}>
            {title}
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
            {shortDescription}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
});

VideoGuideCard.defaultProps = {
  bg: "white"
};

export default VideoGuideCard;
